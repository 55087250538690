@media print {
  html,
  body {
    height: auto !important;
    overflow: initial !important;
  }

  .vex-toolbar,
  .sidenav {
    display: none !important;
  }

  .content {
    margin-top: 0 !important;
  }

  .mat-mdc-drawer-container {
    overflow: visible !important;
  }

  .mat-mdc-drawer-side {
    border-right: none !important;
  }

  .sidenav-content {
    margin-left: 0 !important;
    @apply bg-foreground;
  }

  .vex-footer {
    display: none !important;
  }

  .config-panel-toggle {
    display: none !important;
  }
}
