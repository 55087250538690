@use '@angular/material' as mat;

$custom-primary: (
  50: #254092,
  100: #254092,
  200: #254092,
  300: #254092,
  400: #254092,
  500: #254092,
  600: #254092,
  700: #254092,
  800: #254092,
  900: #254092,
  A100: #254092,
  A200: #254092,
  A400: #254092,
  A700: #254092,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);

// Typography
//$custom-typography: mat-typography-config(
//  $font-family: Raleway,
//  $headline: mat-typography-level(24px, 48px, 400),
//  $body-1: mat-typography-level(16px, 24px, 400)
//);
//@include angular-material-typography($custom-typography);

// Default colors

$my-app-primary: mat.m2-define-palette($custom-primary, 700, 100, 800);
$my-app-accent: mat.m2-define-palette(mat.$m2-teal-palette, 700, 100, 800);

$my-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
    ),
  )
);
@include mat.all-component-themes($my-app-theme);

// Dark theme
$dark-primary: mat.m2-define-palette(mat.$m2-grey-palette);
$dark-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$dark-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);

//$dark-theme: mat.m2-define-dark-theme($dark-primary, $dark-accent, $dark-warn);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
  )
);

.dark-theme {
  @include mat.all-component-themes($dark-theme);
}

// Light theme
$light-primary: mat.m2-define-palette(mat.$m2-grey-palette, 200, 500, 300);
$light-accent: mat.m2-define-palette(mat.$m2-brown-palette, 200);
$light-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette, 200);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
  )
);

.light-theme {
  @include mat.all-component-themes($light-theme);
}
