// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// SCSS Variables
@import 'var';

// Mixins
@import 'partials/mixins';

// Fonts
@import 'partials/fonts/inter';

// Styles / CSS Variables
@import 'partials/styles/style-dark';
@import 'partials/styles/style-light';
@import 'partials/styles/style-default';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vex-theme);

// Partials
@import 'partials/horizontal';
@import 'partials/misc';
@import 'partials/vertical';
@import 'partials/print';
@import 'partials/typography';
@import 'partials/plugins/@angular/material/index';

// Layouts
@import 'partials/layouts/layout-apollo';
@import 'partials/layouts/layout-ares';
@import 'partials/layouts/layout-hermes';
@import 'partials/layouts/layout-ikaros';
@import 'partials/layouts/layout-poseidon';
@import 'partials/layouts/layout-zeus';

// Plus imports for other components in your app.

/* You can add global styles to this file, and also import other style files */

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
  font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11', 'salt', 'calt', 'case';
  @apply font-sans leading-normal antialiased;
}

body {
  height: 100%;
  letter-spacing: -0.006em;
  @apply text-sm text-black;
}

div {
  box-sizing: border-box;
}
