@use '@angular/material' as mat;
@import '../../var';

.vex-style-dark {
  @include mat.all-component-colors($vex-dark-theme);

  // Foreground
  --background-app-bar: #{map-get(map-get($vex-dark-theme, background), app-bar)};

  // Background
  --footer-background: var(--background-foreground);
  --navigation-background: #{map-get(map-get($vex-dark-theme, background), card)};
  --toolbar-background: #{map-get(map-get($vex-dark-theme, background), card)};
  --background-base: #{map-get(map-get($vex-dark-theme, background), background)};

  // Colors
  --background-foreground: #{map-get(map-get($vex-dark-theme, background), card)};
  --background-foreground-rgb: #{red(
        map-get(map-get($vex-dark-theme, background), card)
      )},
    #{green(map-get(map-get($vex-dark-theme, background), card))},
    #{blue(map-get(map-get($vex-dark-theme, background), card))};
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: #{$light-primary-text};
  --toolbar-color: #{$light-primary-text};
  --text-color-light: #{$dark-primary-text};

  // Toolbar
  --foreground-divider: #{map-get(map-get($vex-dark-theme, foreground), divider)};
  --text-hint: #{$light-disabled-text};

  // Navigation
  --text-hint-light: #{$dark-disabled-text};
  --background-hover: #{map-get(map-get($vex-dark-theme, background), hover)};

  // Secondary Toolbar
  --text-secondary: #{$light-secondary-text};

  // Footer
  --text-secondary-light: #{$dark-secondary-text};
  --secondary-toolbar-background: var(--background-foreground);
}
