html,
body,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
div:not(.next-slide, .prev-slide),
button small {
  //font-family: 'Inter', sans-serif !important;
  //font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
  color: unset !important;
}

@font-face {
  font-family: helvetica_custom;
  src: url(../../assets/fonts/helveticaneuelt-arabic-55-roman.ttf) format('opentype');
}

@font-face {
  font-family: Sakkal Majalla;
  src: url(../../assets/fonts/majalla.ttf) format('opentype');
}

*:not(.fa):not(mat-icon):not(#print-section div):not(#print-section span):not(
    .certificate-pr div,
    span,
    p
  ) {
  font-family: 'helvetica_custom', 'arial', sans-serif !important;
}

.angular-editor-button i,
.angular-editor-button span {
  font: 14px/1 FontAwesome !important;
}

input,
textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular,
  Consolas,
  Liberation Mono,
  Menlo,
  monospace;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

blockquote {
  background: rgba(var(--primary-color), 0.1);
  border-left: 3px solid theme('backgroundColor.primary');
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em;

  > * {
    margin: 0;
  }
}

tbody tr td img {
  min-width: 2.25rem;
}

.custom {
  height: 55px !important;
  width: 100%;
  margin-bottom: 25px !important;
  background: #f6f6f6 !important;
  border-bottom: 1px solid rgba(82, 63, 105, 0.42);

  &:focus-visible {
    outline: unset !important;
  }

  &:focus {
    border-bottom: 2px solid #6366f1 !important;
  }

  &.not_valid {
    border: 2px solid red !important;
  }

  &:hover {
    border-bottom: 1px solid rgba(82, 63, 105, 0.87);
  }
}

ng-multiselect-dropdown .multiselect-dropdown {
  min-height: 55px !important;
  width: 100%;
  margin-bottom: 25px !important;
  background: #f6f6f6 !important;

  &:focus-visible {
    outline: unset !important;
  }

  &:focus {
    border-bottom: 2px solid #6366f1 !important;
  }

  &.not_valid {
    border: 2px solid red !important;
  }

  &:hover {
    border-bottom: 1px solid rgba(82, 63, 105, 0.87);
  }
}

ngx-intl-tel-input.ng-invalid .custom:focus {
  border-bottom: 2px solid red !important;
}

ng-multiselect-dropdown .multiselect-dropdown {
  div:nth-child(1) {
    height: 100% !important;

    .dropdown-btn {
      min-height: 55px;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: flex-start !important;
      align-items: center !important;
      border-top: unset !important;
      border-left: unset !important;
      border-right: unset !important;
      border-radius: unset !important;
    }
  }
}

app-page-layout-header {
  //position: sticky;
  //top: 30px;
  //left: 0;
  //z-index: 10;
  //right: 0;
  //padding-bottom: 0px;
  //background: #47709b;
  //color: #fff;
  //~ *{
  //  color: #fff;
  //}
}

.content-overflow {
  height: 70vh !important;
  overflow-x: hidden !important;
}

.page-title {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
}

.toast-custom-position {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.align-items-center {
  align-items: center;
}

tr td img {
  object-fit: contain !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  max-width: unset !important;
  margin: 5px 5px;

  span {
    color: #fff !important;
  }
}

.bg-app-bar.sticky {
  z-index: 10;
  background: var(--header-color) !important;
  color: #ffffff;
  height: 45px;

  h2 span {
    font-size: 16px;
  }

  //width: 100vw !important;
  mat-icon {
    color: #ffffff;
  }
}

.bg-app-bar {
  .title {
    color: #ffffff;
  }
}

.inner-content {
  max-height: calc(100vh - var(--toolbar-height) - var(--footer-height) - 7vh);
}

.img-fluid {
  height: 150px;
  object-fit: contain;
}

.drag-drop {
  height: 200px;

  .act-div {
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.mat-mdc-option-text {
  display: flex !important;

  img {
    width: 20px;
    height: 20px;
  }
}

.filter-title {
  cursor: pointer;

  button {
    border-radius: 9999px !important;
    margin: 0 5px !important;
  }
}

.multiselect-dropdown .dropdown-btn span:nth-child(1) {
  color: rgba(0, 0, 0, 0.6);
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  right: 8px !important;

  &:before {
    border-color: #838181 transparent !important;
    border-width: 4px 4px 0 !important;
  }
}

.quick-access-item {
  mat-icon,
  div {
    cursor: pointer;
  }

  &:hover {
    .remove-icon {
      display: inline-block !important;
    }
  }
}

.remove-icon {
  top: 2px;
  right: 2px;
  display: none !important;

  &:hover {
    color: red;
  }
}

.form-sticky-title {
  max-height: calc(100vh - var(--toolbar-height) - var(--footer-height) - 7vh);
  overflow-x: hidden;
  overflow-y: auto;
}

.quote {
  color: #8cc540;
  font-style: italic;
}

.redText {
  color: Red;
  font-size: 12px;
  font-family: Arial;
}

h1.titleText {
  color: #69a8cf;
  text-align: left;
  margin-top: 40px;
  font-weight: normal;
}

//.angular-editor-toolbar .angular-editor-toolbar-set .foreground:after{
// left: -17px !important;
//}
.drag-drop {
  border: 2px dashed gray;
  background-color: #ebebeb;
  text-align: center;
  padding: 5rem;
  margin-top: 10px;
}

.act-div {
  align-items: center;
}

.row-wrap {
  flex-wrap: wrap !important;
}

img {
  object-fit: fill;
}

.fa {
  cursor: pointer;
  color: var(--primary-color);
}

.mat-mdc-slide-toggle {
  height: 40px;
  //width: 80px;
}

.mat-mdc-slide-toggle-bar {
  height: 32px !important;
  width: 60px !important;
  border-radius: 17px !important;
  padding: 14px !important;
}

.mat-mdc-slide-toggle-thumb-container {
  top: 2px !important;
  left: 2px !important;
}

.mat-mdc-slide-toggle-thumb {
  height: 27px !important;
  //margin: 5px 0 0 0;
  width: 26px !important;
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled)
.mat-mdc-slide-toggle-thumb-container {
  left: unset !important;
  right: 24px;
}

.mat-mdc-slide-toggle .mat-mdc-slide-toggle-bar {
  background-color: var(--color-grey);
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled)
.mat-mdc-slide-toggle-bar {
  background-color: var(--primary-color);
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled)
.mat-mdc-slide-toggle-thumb {
  background-color: var(--color-third);
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled)
.mat-mdc-slide-toggle-thumb {
  background-color: var(--color-third);
}

.step-hidden {
  display: none !important;
}

ngx-mat-datetime-content .actions .mat-mdc-button .mat-icon {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

@media (max-width: 1124px) {
  .actions button {
    min-width: unset !important;
    max-width: 100px;
  }
  .mat-step-header .mat-step-label {
    display: none !important;
  }
  .mat-stepper-horizontal-line {
    display: none !important;
  }
  .mat-step-label {
    margin-bottom: 0 !important;
  }
  .quick-access-item {
    div {
      font-size: 9px;
    }

    mat-icon {
      width: 14px !important;
      height: 14px !important;
    }
  }
  mat-step-header {
    padding: 0 24px !important;
  }
}

.custom-phone {
  .custom {
    //width: 97.5%;
  }
}

.img-container {
  position: absolute !important;
  top: 100px !important;
  bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 9;
  font-family: unset !important;
}

.reference {
  font-size: 18px;
  background: #f5f5f8;
  padding: 3px 10px;
  border-radius: 5px;
  letter-spacing: 2px;
}

.bg-primary {
  background: var(--primary-color) !important;
}

.text-primary,
.txt-primary {
  color: var(--primary-color) !important;
}

.mat-mdc-menu-panel {
  min-height: unset !important;
}

.ltr_dir {
  direction: ltr !important;
  text-align: left !important;
}

.rtl_dir {
  direction: rtl !important;
  text-align: right !important;
}

.mat-mdc-form-field-empty.mat-mdc-form-field-label {
  height: 18px !important;
}

.rtl_dir
.angular-editor-toolbar
.angular-editor-toolbar-set
.foreground
:after {
  left: auto !important;
}

input.mat-mdc-input-element {
  height: 20px !important;
}

.mat-mdc-error,
.mat-mdc-hint {
  height: 15px !important;
}

.mat-mdc-form-field-label:not(.mat-mdc-form-field-empty) {
  height: 18px;
}

.title {
  font: unset !important;
  font-size: 20px !important;
}

.overflow-auto.-mt-16 {
  margin-top: -55px !important;
}

.mat-mdc-button.mat-mdc-primary {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
}

.breadcrumbs__container {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  align-items: center;
  background-color: unset !important;
  font-weight: 600;

  li.breadcrumbs__item {
    font-size: 18px;
    padding: 0 !important;
    font-weight: 500;

    a {
      color: var(--primary-color) !important;
      text-decoration: none;
      background-color: transparent;
      opacity: 1;
      font-size: 18px;
      font-weight: 500;
    }

    a:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  li.breadcrumbs__item--active {
    background: unset !important;
    color: #000000 !important;

    a {
      color: #000000 !important;
      text-decoration: none;
      background-color: transparent;
    }
  }

  li.breadcrumbs__item:nth-child(n + 2)::before {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
    color: #d8d8d8;
    height: 14px;
    width: 8.24px;
    font-weight: 900;
    //font-family: "Font Awesome 5 Free";
    content: url('../../assets/img/svg/dot.svg');
  }
}

@media (max-width: 760px) {
  .breadcrumbs__container {
    padding: 0 !important;

    a.mat-mdc-button {
      padding: 0 10px !important;
    }
  }
  .breadcrumbs__container:not(:nth-child(1)):before {
    margin: 0 !important;
  }
}

.mat-mdc-select-value {
  height: 30px;
}

mat-paginator .mat-mdc-select-value {
  height: 100%;
}

.section-label {
  font-size: 16px;
}

.vex-page-layout-content {
  padding-bottom: 0 !important;
}

.table-content-with-tabs {
  max-height: calc(
    100vh - var(--toolbar-height) - var(--footer-height) - 7vh
  ) !important;
}

.disabled-td {
  color: gray;
  pointer-events: none;
  user-select: none;
}

.mat-mdc-checkbox-label {
  font-size: 15px;
}

.vertical-logo {
  width: 100px;
}

.sidenav-toolbar .main-logo {
  height: calc(var(--toolbar-height) - 10px) !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
.mdc-notched-outline__notch {
  border-right: unset;
}

.input-with-icon {
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    margin-right: 50px !important;
  }
}

.mdc-button .mdc-button__label {
  line-height: 2;
}

.multiselect-dropdown {
  width: 100% !important;
  line-height: 3.5;

  .dropdown-list ul li:first-child {
    overflow: hidden !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: auto !important;
}

.multiselect-dropdown span.optext .optdel:before {
  content: '\f00d' !important;
  font: normal normal normal 14px/1 FontAwesome;
}

.multiselect-dropdown span.optext .optdel {
  float: right !important;
  margin: 0 -6px 1px 5px !important;
  font-size: 0 !important;
  cursor: pointer !important;
  color: #666 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 0 !important;
}

.multiselect-dropdown .placeholder {
  background-color: unset !important;
  opacity: 1 !important;
  color: #6f6e6e !important;
}

.multiselect-dropdown .dropdown-btn {
  height: 60px !important;
  display: flex !important;
  justify-content: start;
  align-items: center;
  border-color: #d5d5d5 !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  top: 16px !important;
}

.multiselect-dropdown-list {
  max-height: 15rem !important;
  height: unset !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined
.mdc-notched-outline--upgraded
.mdc-floating-label--float-above {
  margin-right: 0 !important;
}

.popup-title {
  font-size: 19px;
  font-weight: 600;
}

.active-icon {
  background: var(--primary-color) !important;
  color: #ffffff !important;
  border-radius: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}

.mat-dialog-content {
  overflow: hidden !important;
}

.search-menu {
  max-height: 400px !important;
  overflow: auto !important;
  min-width: 390px !important;
}

.mat-mdc-option:hover .mat-icon,
:root .mat-mdc-option.mat-mdc-active .mat-icon {
  color: var(--primary-color) !important;
}

.images {
  gap: 16px;

  .empty-img {
    border-radius: 8px;
    border: 1px dashed #cbd5e1;
    background: #f8fafc;
    padding: 27px;
    cursor: pointer;

    svg {
      width: 44px;
      height: 44px;
      margin-bottom: 8px;

      span {
        color: #475569;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .img {
    position: relative;

    .img-placeholder {
      width: 128px;
      height: 128px;
      border-radius: 8px;
    }

    .remove {
      border-radius: 50%;
      width: 24px;
      top: 8px;
      right: 8px;
      position: absolute;
      font-size: 19px;
      background: #f1f5f9;
    }
  }
}

.bg-light-grey {
  background-color: var(--light-grey-color) !important;
}

.ltr {
  direction: ltr;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-left: 12px;
  font-size: 20px !important;
}

.ant-collapse-item {
  div[role='button'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.help {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 9 !important;
}

.ant-collapse-header {
  font-size: 20px;
}

mat-paginator .mat-mdc-form-field-infix {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field-bottom-align {
  //height: 10px !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mat-expansion-indicator::after {
  border-color: var(--primary-color);
}

.table-drop-down {
  width: 8rem !important;
  height: 100%;
  display: flex !important;
  background: #cdcdcd;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: unset !important;
}

.ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-hidden {
  display: none;
}

.section-title {
  font-size: 19px;
  font-weight: 600;
}

.ltr_dir {
  direction: ltr !important;
  text-align: left !important;
}

.rtl_dir {
  direction: rtl !important;
  text-align: right !important;
}

.ant-table-header,
.ant-table-body {
  overflow: hidden !important;
}

.icon-display {
  transform: scale(0.7);
}

.icon-position {
  margin-top: 25px;
}

.user_img {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  object-fit: contain;
}

.custom-dialog-container .mat-mdc-dialog-content {
  overflow: visible !important;
}

table {
  th.w-6,
  td.w-6 {
    width: 3.5rem;
  }
}

.custom-dropdown-class .multiselect-dropdown .dropdown-list {
  position: relative !important;
}

.loader {
  position: fixed !important;
  z-index: 9999;
  left: 50%;
  //margin-left: -50px;
  top: 42%;
}

.loadText {
  // position: fixed !important;
  // z-index: 100;
  // left: 50.5%;
  // margin-top: 21.5%;
  color: #000000;
}

.loadTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 40%;
  position: fixed !important;
  z-index: 100;
  left: 50%;
  top: 42%;
}

.has-text {
  background: #dae2fd;
}

@media (max-width: 1000px) and (min-width: 900px) {
  .loader {
    left: 46%;
    top: 46%;
  }
  .loadTextContainer {
    left: 46%;
    top: 46%;
  }
}

@media (max-width: 900px) and (min-width: 600px) {
  .loader {
    left: 45%;
    top: 45%;
  }
  .loadTextContainer {
    left: 45%;
    top: 45%;
  }
}

@media (max-width: 600px) {
  .loader {
    left: 40%;
    top: 40%;
  }
  .loadTextContainer {
    left: 40%;
    top: 40%;
  }
}

.loading__anim {
  width: 60px;
  height: 60px;
  border: 5px solid rgba(189, 189, 189, 0.25);
  border-left-color: var(--primary-color);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  animation: rotate 600ms infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

.dotted-loader {
  width: 60px;
  height: 60px;
  border: 3px dotted var(--primary-color);
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.dotted-loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted var(--primary-color);
  border-style: solid solid dotted;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid var(--primary-color) !important;
  background: var(--primary-color) !important;
  padding: 0px 5px 5px 5px !important;
}

ngx-mat-datepicker-content {
  background-color: var(--mat-datepicker-calendar-container-background-color);
  color: var(--mat-datepicker-calendar-container-text-color);
  box-shadow: var(--mat-datepicker-calendar-container-elevation-shadow);
  border-radius: var(--mat-datepicker-calendar-container-shape);
}

.mat-mdc-radio-checked .mdc-label{
  color: var(--primary-color) !important;
  font-weight: 600;
}

ag-charts {
  display: block;
  width: 100%;
}
