.horizontal-layout {
  // Workaround: CSS calc() functions can't deal with values without a unit (e.g. 0 instead of 0px), when this is 0, SCSS or PostCSS make it a 0 instead of 0px, breaking the layout.
  --navigation-height: 0.00001px;
  --toolbar-width: 100%;

  //@screen lg {
  //  --toolbar-width: calc(100% - var(--sidenav-width));
  //
  //  &.sidenav-collapsed {
  //    --toolbar-width: calc(100% - var(--sidenav-collapsed-width));
  //  }
  //}

  .sidenav-container {
    /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
       causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
    flex: 1;
  }

  &.has-fixed-footer {
    &.scroll-disabled .content {
      height: calc(100% - var(--toolbar-height) - var(--footer-height));
    }
  }
}

@screen lg {
  body:not([dir='rtl']) {
    .horizontal-layout {
      &.sidenav-collapsed .sidenav-content {
        margin-left: var(--sidenav-collapsed-width) !important;
        margin-right: 0 !important;
      }

      &:not(.sidenav-collapsed) .sidenav-content {
        margin-left: var(--sidenav-width) !important;
        margin-right: 0 !important;
      }
    }
  }

  [dir='rtl'] {
    .horizontal-layout {
      &.sidenav-collapsed .sidenav-content {
        margin-right: var(--sidenav-collapsed-width) !important;
        margin-left: 0 !important;
      }

      &:not(.sidenav-collapsed) .sidenav-content {
        margin-right: var(--sidenav-width) !important;
        margin-left: 0 !important;
      }
    }
    .vex-sidenav-item__label {
      font-size: 16px;
      font-weight: 600;
    }
    .vex-sidenav-subheading {
      font-size: 17px !important;
      font-weight: 600 !important;
    }
  }
}
