@use '@angular/material' as mat;

// Previously "$sidenav-background"
$sidenav-background: #1a202e !default;

$config: mat.m2-define-typography-config(
  $font-family: var(--font),
  $body-2:
    mat.m2-define-typography-level(14px, 24px, 500, $letter-spacing: -0.006em),
  $body-1:
    mat.m2-define-typography-level(14px, 20px, 400, $letter-spacing: -0.006em),
  $caption: mat.m2-define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $button:
    mat.m2-define-typography-level(14px, 14px, 500, $letter-spacing: -0.006em),
) !default;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vex-primary: mat.m2-define-palette(
  (
    100: rgba(var(--primary-color), 0.1),
    500: rgba(0, 65, 95, 1),
    700: rgba(var(--primary-color), 1),
    contrast: (
      100: rgba(var(--color-primary-contrast), 1),
      500: rgba(var(--color-primary-contrast), 1),
      700: rgba(var(--color-primary-contrast), 1),
    ),
  )
) !default;

$vex-accent: mat.m2-define-palette(
  (
    100: rgba(var(--color-accent), 0.1),
    500: rgba(255, 64, 129, 1),
    700: rgba(var(--color-accent), 1),
    contrast: (
      100: rgba(var(--color-accent-contrast), 1),
      500: rgba(var(--color-accent-contrast), 1),
      700: rgba(var(--color-accent-contrast), 1),
    ),
  )
) !default;

// The warn palette is optional (defaults to red).
$vex-warn: mat.m2-define-palette(
  (
    100: rgba(var(--color-warn), 0.1),
    500: rgba(244, 67, 54, 1),
    700: rgba(var(--color-warn), 1),
    contrast: (
      100: rgba(var(--color-warn-contrast), 1),
      500: rgba(var(--color-warn-contrast), 1),
      700: rgba(var(--color-warn-contrast), 1),
    ),
  )
) !default;

$vex-theme-foreground: (
  text: black,
  elevation: #000,
  divider: rgba(82, 63, 105, 0.06),
) !default;

$vex-theme-background: (
  app-bar: #ebebee,
) !default;

// Create the theme object (a Sass map containing all of the palettes).
$vex-theme: (
  primary: $vex-primary,
  accent: $vex-accent,
  warn: $vex-warn,
  is-dark: false,
  foreground:
    map_merge(mat.$m2-light-theme-foreground-palette, $vex-theme-foreground),
  background:
    map_merge(mat.$m2-light-theme-background-palette, $vex-theme-background),
) !default;

$vex-dark-theme-background: (
  background: lighten($sidenav-background, 5),
  card: $sidenav-background,
  app-bar: darken($sidenav-background, 5),
  dialog: $sidenav-background,
  status-bar: darken($sidenav-background, 5),
) !default;

$vex-dark-theme: (
  primary: $vex-primary,
  accent: $vex-accent,
  warn: $vex-warn,
  is-dark: true,
  foreground: mat.$m2-dark-theme-foreground-palette,
  background:
    map_merge(mat.$m2-dark-theme-background-palette, $vex-dark-theme-background),
) !default;

$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);

$primary: mat.m2-define-palette(mat.$m2-blue-palette, 800);
$accent: mat.m2-define-palette(mat.$m2-cyan-palette, 700);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 600);
$error: mat.m2-define-palette(mat.$m2-orange-palette, 50);
$success: mat.m2-define-palette(mat.$m2-green-palette, 600);
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);
$dark-primary: mat.m2-define-palette(mat.$m2-blue-palette, 900);
$dark-accent: mat.m2-define-palette(mat.$m2-teal-palette, 600);
$dark-success: mat.m2-define-palette(mat.$m2-green-palette, 800);
$dark-warn: mat.m2-define-palette(mat.$m2-pink-palette, 400);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
      warn: $dark-warn,
    ),
  )
);
$dark-background: map-get($dark-theme, background);
$dark-foreground: map-get($dark-theme, foreground);

// Default Theme
@include mat.all-component-themes($theme);
// Dark Theme
.app-dark {
  @include mat.all-component-colors($dark-theme);
}

// Create your Sass color vars (will be available in all the project)
$my-primary: mat.m2-get-color-from-palette($primary);
$my-accent: mat.m2-get-color-from-palette($accent);
$my-warn: mat.m2-get-color-from-palette($warn);
$my-success: mat.m2-get-color-from-palette($success);
$my-error: mat.m2-get-color-from-palette($error);

$my-dark-primary: mat.m2-get-color-from-palette($dark-primary);
$my-dark-accent: mat.m2-get-color-from-palette($dark-accent);
$my-dark-warn: mat.m2-get-color-from-palette($dark-warn);
$my-dark-success: mat.m2-get-color-from-palette($dark-success);

//custom-variables
$light-color: #ffffff;

// .primary-color{
//   color: $my-primary;
// }
.app-dark {
  .primary-bg {
    background: $my-dark-primary !important;
    // color:$light-color !important;
  }

  .primary-text {
    color: $my-dark-primary !important;
  }

  .success-bg {
    background: $my-dark-success !important;
    // color:$light-color !important;
  }

  .success-text {
    color: $my-dark-success !important;
  }

  .warn-bg {
    background: $my-dark-warn !important;
    // color:$light-color !important;
  }

  .warn-text {
    color: $my-dark-warn !important;
  }

  .accent-text {
    color: $my-dark-accent !important;
  }

  .accent-bg {
    background: $my-dark-accent !important;
    // color:$light-color !important;
  }
}

.info-card-1 {
  background: #fcd394 !important;
  color: white !important;
  font-weight: bold;
}

.info-card-2 {
  background: #5ac0f1 !important;
  color: white !important;
  font-weight: bold;
}

.info-card-3 {
  background: #b8b5b5 !important;
  color: white !important;
  font-weight: bold;
}

.info-card-4 {
  background: #71cea8 !important;
  color: white !important;
  font-weight: bold;
}

.primary-bg {
  background: $my-primary !important;
  color: $light-color !important;
}

.primary-text {
  color: var(--primary-color) !important;
}

.success-bg {
  background: $my-success !important;
  color: $light-color !important;
}

.success-text {
  color: $my-success !important;
}

.warn-bg {
  background: $my-warn !important;
  color: $light-color !important;
}

.warn-text {
  color: $my-warn !important;
}

.accent-text {
  color: $my-accent !important;
}

.accent-bg {
  background: $my-accent !important;
  color: $light-color !important;
}

.error-text {
  color: $my-error !important;
}

.danger-text {
  color: #dc3545 !important;
}

.error-bg {
  background: $my-error !important;
  color: $light-color !important;
}
//
// General
//

//Sidebar

$sidebar-width: 16rem !default;
$sidebar-text-color: #e4e7ef;
$sidebar-bg-color: #37474f;
$compact-sidebar-width: 70px !default;

//  Header
$header-bg-color: #f0f2f7;
$header-font-color: #000000;

//Md card
$mat-card-padding-top-bottom: 0.875rem;
$mat-card-padding-left-right: 1.5rem;
$mat-card-bg-color: #ffffff;
$mat-card-title-font-color: #37474f;
$mat-card-hover-effect: 0 0 10px rgba(0, 0, 0, 0.2);
//mat-icon

$mat-card-icon-color: #868e96;

//Main Container
$container-bg: #f0f2f7;
//Rounded Icons
$highlight-icons: #ffffff;
$highlight-icon-color: #37474f;

// Typography
//

$font-color: #37474f;
//Meta post
$meta-post-color: #9c9fad;
$meta-post-font-weight: 600;
//Breadcrumb
$breadcrumb-font-size: 0.875rem;
$breadcrumb-font-color: #37474f;

//Mat list hover bg
$mat-list-hover-bg-color: rgba(0, 0, 0, 0.2);
$html-font-size: 16px;
$mat-font-family: 'Nunito Sans', sans-serif;
$font-size-base: 1rem !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

//Heading Font Size

$font-size-h1: 2rem !default;
$font-size-h2: 1.625rem !default; //26px
$font-size-h3: 1.375rem !default; //22px
$font-size-h4: 1.125rem !default; //18px
$font-size-h5: 0.875rem !default; //14px
$font-size-h6: 0.75rem !default; //12px
//Heading Font color

$h1-font-color: inherit;
$h2-font-color: inherit;
$h3-font-color: inherit;
$h4-font-color: inherit;
$h5-font-color: inherit;
$h6-font-color: inherit;

$headings-margin-bottom: (1rem / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

//
// Radius
//
//Mat -card
$border-radius-base: 8px;

//overlay-content
$overlay-content-overlay: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0) 0%,
  #000000 100%
);
$overlay-content-text: #ffffff;

//timeline
$card-elems-radius: 8px;

//Crypto Variables
$trade-prefix-bg: #eeeeee;

// preloader-variable

$loader-bg: #ffffff;
$loader-color: $my-primary;
