@import '~simplebar/dist/simplebar.css';
@import '~highlight.js/styles/androidstudio.css';
// Vex Core
@import '../node_modules/@angular/material/core/theming/all-theme';
@import 'src/@config/styles/core';
@import './theme';
@import 'src/@config/styles/partials/mixins';
@import 'src/@config/styles/partials/utilities/utilities';
/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
.mat-form-field .mat-form-field-label-wrapper label {
  line-height: 17px;
  font-size: 14px;
}
