[dir='rtl'] {
  .mat-mdc-menu-item:not(.search-menu-item) .mat-icon {
    margin-left: unset !important;
    margin-right: 16px !important;
  }

  .rtl_dir
    .angular-editor-toolbar
    .angular-editor-toolbar-set
    .foreground
    :after {
    left: auto !important;
  }

  .mat-mdc-slide-toggle-thumb-container {
    right: 2px !important;
  }

  .mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled)
    .mat-mdc-slide-toggle-thumb-container {
    right: 16px !important;
  }

  .multiselect-dropdown .dropdown-btn {
    text-align: right !important;
  }

  .mat-mdc-menu-item {
    //justify-content: end !important;
    .mat-mdc-checkbox {
      width: 100%;
    }

    .mat-mdc-checkbox-layout {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .chat-pattern {
    margin-right: 400px;
    margin-left: unset !important;
  }

  .mat-mdc-dialog-container {
    direction: rtl !important;
  }

  .iti--allow-dropdown .iti__flag-container {
    right: 0 !important;
    left: auto !important;
  }

  .iti.separate-dial-code input.custom,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 input.custom,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 input.custom,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input.custom {
    padding-right: 98px !important;
    padding-left: 0 !important;
  }

  .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    left: 8px !important;
    right: unset !important;
  }

  .dropdown {
    direction: rtl;
  }

  .dropdown-heading-icon {
    margin-left: var(--padding-12) !important;
    margin-right: 0 !important;
  }

  .notification-icon {
    margin-left: var(--padding) !important;
    margin-right: 0 !important;
  }

  .country-dropdown {
    right: 0 !important;
  }

  .iti__country {
    direction: rtl;
    text-align: right;
  }

  .mat-mdc-option {
    direction: rtl;
  }

  .table-header {
    //position: fixed !important;
    //z-index: 9 !important;
    //width: 82.3%;
    //top: unset !important;
  }

  .table-paginator {
    //position: fixed !important;
    //z-index: 9 !important;
    ///* width: 100%; */
    //top: unset !important;
    //bottom: 115px !important;
    //left: 3%;
    //right: 15.8%;
    //padding-bottom: 10px;
  }

  .table-content {
    display: grid;
    grid-template-columns: 1fr;
    //grid-template-rows: 50px 1fr;
  }

  .mat-mdc-form-field-outline-start {
    width: unset !important;
  }

  .table-header {
    grid-column: 1;
    grid-row: 1;
    position: sticky;
    top: 0;
    right: 0;
  }

  .item-box {
    &:first-child {
      border: 1px solid #000000;
    }

    &:last-child {
      border-right: none !important;
    }
  }

  .sidenav-toolbar .main-logo {
  }

  .mat-stepper-vertical-line::before {
    left: unset !important;
    right: -13px !important;
    top: -20px !important;
  }

  .multiselect-dropdown {
    background-position: left 0.75rem center !important;
  }

  .search-menu button {
    direction: rtl;
  }

  .invalid-grade {
    left: 20px !important;
    right: unset !important;
  }

  .mat-mdc-menu-submenu-icon {
    padding-right: 0 !important;
  }
}
